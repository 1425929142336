export default {
	typeOptions: [{
		label: '平台',
		value: 1,
	},
	{
		label: '机构',
		value: 2,
	},
	{
		label: '药师',
		value: 3,
	},

	],
	genderOptions:[
		{
			label:"男",
			value:"46"
		},
		{
			label:"女",
			value:"47"
		}
	],
	titleOptions:[
		{
			label:"医士",
			value:2
		},
		{
			label:"医师",
			value:1
		},
		{
			label:"住院医师",
			value:3
		},
		{
			label:"主治医师",
			value:4
		},
		{
			label:"副主任医师",
			value:5
		},
		{
			label:"主任医师",
			value:6
		},
		{
			label:"药士",
			value:7
		},
		{
			label:"药师",
			value:8
		},
		{
			label:"主管药师",
			value:9
		},
		{
			label:"副主任药师",
			value:10
		},
		{
			label:"主任药师",
			value:11
		},
	],
	// 备案状态 ：1.有效、2.挂起、3.未备案、4.备案过期、5.无效
	"superviseStatus" : [
		{
			label:"有效",
			value:1
		},
		{
			label:"挂起",
			value:2
		},
		{
			label:"未备案",
			value:3
		},
		{
			label:"备案过期",
			value:4
		},
		{
			label:"无效",
			value:5
		},
	],
}
